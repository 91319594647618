<script lang="ts">
	import { page } from '$app/stores'
	import ConditionalAnchor from '$lib/components/ConditionalAnchor.svelte'
	import Form from '$lib/components/WoltAdvisor/Form.svelte'
	import { PUBLIC_WOLTAIR_WEB_CODE } from '$env/static/public'

	type USPBox = {
		title?: string
		subtext?: string
		icon?: {
			data: {
				attributes: {
					url: string
				}
			}
		}
		link?: string
		linkText?: string
	}

	export let backgroundImage =
		PUBLIC_WOLTAIR_WEB_CODE === 'WOLTAIR_IT'
			? '/images/backgrounds/woltair-homepage-background-it.webp'
			: '/images/backgrounds/woltair-homepage-background-de.webp'
	export let title: string
	export let showHVAC: boolean
	export let showPV: boolean
	export let uspBoxes: USPBox[]
	export let rating: {
		text?: string
		link?: string
		stars?: number
		image?: {
			data: {
				attributes: {
					url: string
				}
			}
		}
	}
	export let showContactFields = false
	export let splitTestActive = false
</script>

<div class="page-fullwidth h-fit overflow-hidden md:-mt-32">
	<div class="hidden md:block">
		<div class="absolute -z-10 h-full w-full bg-black" />
		<div
			class="fix-safari-stutter absolute -z-10 h-full w-full bg-cover bg-center bg-no-repeat opacity-50 blur-3xl"
			style:background-image="url('{backgroundImage}')"
		/>
	</div>

	<div
		class="z-20 mx-auto h-fit max-w-[2000px] md:pt-20"
		class:background-cz={PUBLIC_WOLTAIR_WEB_CODE === 'WOLTAIR_CZ'}
		class:background-pl={PUBLIC_WOLTAIR_WEB_CODE === 'WOLTAIR_PL'}
		class:background-de={PUBLIC_WOLTAIR_WEB_CODE === 'WOLTAIR_DE'}
		class:background-it={PUBLIC_WOLTAIR_WEB_CODE === 'WOLTAIR_IT'}
		class:pb-24={uspBoxes?.length > 0}
	>
		<div
			class="md:page-wrapper mx-2 flex min-h-[980px] flex-col justify-start pb-[140vw] sm:pb-20 sm:pt-24 md:mx-auto md:h-auto md:max-h-none md:min-h-0 md:justify-start"
		>
			<div class="relative z-10 h-fit max-h-full w-fit max-w-full rounded-2xl bg-w-blue-950">
				<Form {title} {showHVAC} {showPV} {rating} {showContactFields} {splitTestActive} />
			</div>
			{#if uspBoxes?.length > 0}
				<div class="mt-6 md:hidden">
					<div
						class="grid grid-cols-1 overflow-hidden rounded-2xl border border-neutral-200/70 bg-neutral-100/95 shadow-2xl backdrop-blur-xl"
					>
						{#each uspBoxes as box}
							<div class="usp-box border-neutral-200/70 text-w-blue-950">
								<ConditionalAnchor
									condition={!!(box.link && box.linkText)}
									href={box.link}
									cls="flex items-center gap-5 px-8 py-8"
								>
									<div class="flex flex-col gap-2">
										<div class="flex items-center gap-3">
											{#if box?.icon?.data?.attributes?.url}
												<img
													src={box.icon.data.attributes.url}
													alt="Icon"
													class="aspect-square w-6"
												/>
											{/if}
											<h4 class="unstyled text-sm font-bold">{@html box.title}</h4>
										</div>
										<p class="unstyled text-xs opacity-70">{@html box.subtext}</p>
										{#if box.link && box.linkText}
											<div
												class="mt-2 flex items-center gap-2.5 text-sm font-semibold text-w-blue-950/70 transition-colors group-hover:text-w-red-500"
											>
												<img src="/images/icons/woltair-ff-logo.svg" alt="arrow icon" class="h-3" />
												<span>
													{box.linkText}
												</span>
											</div>
										{/if}
									</div>
								</ConditionalAnchor>
							</div>
						{/each}
					</div>
				</div>
			{/if}
		</div>
	</div>
	{#if uspBoxes?.length > 0}
		<div
			class="hidden bg-white md:block"
			class:bg-neutral-100={$page.data?.layout?.background === 'gray'}
		>
			<div class="page-wrapper px-2 md:px-4">
				<div
					class="grid overflow-hidden rounded-2xl bg-neutral-100/95 backdrop-blur-xl md:-mb-24 md:-translate-y-24 md:grid-cols-3"
				>
					{#each uspBoxes as box}
						<div class="usp-box group border-neutral-300 text-w-blue-950">
							<ConditionalAnchor
								condition={!!(box.link && box.linkText)}
								href={box.link}
								cls="flex items-center gap-5 px-8 py-8"
							>
								{#if box?.icon?.data?.attributes?.url}
									<img src={box.icon.data.attributes.url} alt="Icon" class="aspect-square w-11" />
								{/if}
								<div class="flex flex-col gap-2">
									<h4 class="unstyled text-base font-bold">{@html box.title}</h4>
									<p class="unstyled text-sm opacity-70">{@html box.subtext}</p>
									{#if box.link && box.linkText}
										<div
											class="mt-2 flex items-center gap-2.5 text-sm font-semibold text-w-blue-950/70 transition-colors group-hover:text-w-red-500"
										>
											<img src="/images/icons/woltair-ff-logo.svg" alt="arrow icon" class="h-3" />
											<span
												class="transition-transform duration-100 ease-in-out group-hover:translate-x-1.5"
											>
												{box.linkText}
											</span>
										</div>
									{/if}
								</div>
							</ConditionalAnchor>
						</div>
					{/each}
				</div>
			</div>
		</div>
	{/if}

	<!-- <div class="flex flex-col sm:mt-16 md:hidden">
		<p class="text-center text-neutral-600">
			{t('web.simple_leadform.heat_pumps.ourBrands')}
		</p>
		<div class="flex flex-col items-center gap-8">
			<div class="mt-8 flex flex-wrap justify-center gap-8">
				{#each brands as brand}
					<img src={brand.logoGrey} alt={brand.name} class="h-auto w-5/12" />
				{/each}
			</div>

			{#if brandsCount - brands.length > 0}
				<p class="w-full text-center text-neutral-600 md:w-fit">
					+{brandsCount - brands.length}
					{t('web.simple_leadform.ourBrands.more')}
				</p>
			{/if}
		</div>
	</div> -->
</div>

<style lang="scss">
	.background-cz {
		@apply bg-[url('/images/backgrounds/woltair-homepage-background-de-mobile-ver-b.webp')] bg-contain bg-bottom bg-no-repeat;

		@screen sm {
			@apply bg-[url('/images/backgrounds/woltair-homepage-background-de.webp')] bg-cover bg-top bg-no-repeat;
		}
	}
	.background-pl {
		@apply bg-[url('/images/backgrounds/woltair-homepage-background-de-mobile-ver-b.webp')] bg-contain bg-bottom bg-no-repeat;

		@screen sm {
			@apply bg-[url('/images/backgrounds/woltair-homepage-background-de.webp')] bg-cover bg-top bg-no-repeat;
		}
	}
	.background-de {
		@apply bg-[url('/images/backgrounds/woltair-homepage-background-de-mobile-ver-b.webp')] bg-contain bg-bottom bg-no-repeat;

		@screen sm {
			@apply bg-[url('/images/backgrounds/woltair-homepage-background-de.webp')] bg-cover bg-top bg-no-repeat;
		}
	}

	.background-it {
		@apply bg-[url('/images/backgrounds/woltair-homepage-background-it-mobile.webp')] bg-contain bg-bottom bg-no-repeat;

		@screen sm {
			@apply bg-[url('/images/backgrounds/woltair-homepage-background-it.webp')] bg-cover bg-top bg-no-repeat;
		}
	}

	.usp-box {
		&:not(&:last-of-type) {
			@apply border-b;
		}

		@screen md {
			&:not(&:last-of-type) {
				@apply border-b-0;
			}
			&:nth-of-type(n + 4) {
				@apply border-t;
			}
			&:not(&:nth-of-type(3n)) {
				@apply border-r;
			}
		}
	}

	.fix-safari-stutter {
		transform: translateZ(0);
	}
</style>
